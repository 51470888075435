// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    overflow-x: hidden;
    font-family: "Poppins";
    background-color: #F1F5F8;
}

select{    
    border: 0px;
    background-color: #fff;
    
    cursor: pointer;
    width: 100%;    
}

.no-data-found{
    height: 450px;
    padding-top: 220px;
}`, "",{"version":3,"sources":["webpack://./src/style/global.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,sBAAsB;;IAEtB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');\r\n\r\nbody{\r\n    overflow-x: hidden;\r\n    font-family: \"Poppins\";\r\n    background-color: #F1F5F8;\r\n}\r\n\r\nselect{    \r\n    border: 0px;\r\n    background-color: #fff;\r\n    \r\n    cursor: pointer;\r\n    width: 100%;    \r\n}\r\n\r\n.no-data-found{\r\n    height: 450px;\r\n    padding-top: 220px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
