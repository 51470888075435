import React, { useEffect, useState, useCallback, useMemo } from "react";
import Busca from "../../components/busca/buscavenda.jsx";
import Menu from "../../components/menu/menu.jsx";
import Indicador from "../../components/indicador/indicadorvenda.jsx";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import api from "../../services/api.js";

function Vendas() {
    
  const [dadosIndicadores, setDadosIndicadores] = useState({
    "QTD_FECHADA": 0,
    "VALOR_FECHADA": 0,
    "QTD_CANCELADA": 0,
    "VALOR_CANCELADA": 0,
    "QTD_GRAVADO": 0,
    "VALOR_GRAVADO": 0,
    "QTD_VENDA": 0,
    "VALOR_VENDA": 0,
    "QTD_ABERTA": 0,
    "VALOR_ABERTA": 0
  });
  
  const [termoBusca, setTermoBusca] = useState(""); // Termo da busca
  const [criterioBusca, setCriterioBusca] = useState("descricao"); // Critério da busca  
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [isMobile, setIsMobile] = useState(false);
  const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
  const [dados, setDados] = useState([]);
  const [total_registros, setTotalRegistros] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

    const columns = useMemo(() => [
      { name: 'Código', selector: row => row.CODIGO, sortable: true, width: "120px" },
      {
        name: 'Data Emissão',
        selector: row => new Date(row.DATA_EMISSAO).toLocaleDateString('pt-BR', { timeZone: 'UTC' }), // Formata a data no padrão DD/MM/YYYY
        sortable: true
      },      
      { name: 'Cliente', selector: row => row.NOME, sortable: true, compact: true },
      { name: 'Vendedor', selector: row => row.VIRTUAL_VENDEDOR, sortable: true },
      { 
        name: 'Situação', 
        selector: row => row.SITUACAO, 
        sortable: true,
        cell: (row) => {
          let color;
          switch (row.SITUACAO) {
            case 'Fechada':
              color = 'green';
              break;
            case 'Aberta':
              color = 'blue';
              break;
            case 'Gravada':
              color = 'yellow';
              break;
            case 'Cancelada':
              color = 'red';
              break;
            case 'Em Venda':
              color = 'black';
              break;
            default:
              color = 'gray'; // cor padrão caso a situação não seja reconhecida
          }
    
          return <span style={{ color }}>{row.SITUACAO}</span>;
        }
      },
      { 
        name: 'Valor Subtotal', 
        selector: row => row.SUBTOTAL, 
        sortable: true, 
        right: true,
        format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.SUBTOTAL)
      },
      { 
        name: 'Valor Troco', 
        selector: row => row.TROCO, 
        sortable: true, 
        right: true,
        format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.TROCO)
      },
      { 
        name: 'Valor Total', 
        selector: row => row.TOTAL, 
        sortable: true, 
        right: true,
        format: row => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.TOTAL)
      },
        {
          cell: (row) => (
            <>
              <button onClick={() => Editar(row.CODIGO)} className="btn btn-primary ms-3" disabled><i className="bi bi-pencil-square"></i></button>
              <button onClick={() => Excluir(row.CODIGO)} className="btn btn-danger ms-3" disabled><i className="bi bi-trash3-fill"></i></button>
            </>
          )}      
      /*{
        cell: (row) => (
          row.SITUACAO === 'Aberta' ? ( // Verifica se a situação é "Aberta"
            <>
              <button onClick={() => Editar(row.CODIGO)} className="btn btn-primary ms-3">
                <i className="bi bi-pencil-square"></i> {/* Ícone de edição *//*}
              </button>
              <button onClick={() => Excluir(row.CODIGO)} className="btn btn-danger ms-3">
                <i className="bi bi-trash3-fill"></i> {/* Ícone de exclusão *//*}
              </button>
            </>
          ) : null // Se não for "Aberta", não renderiza nada
        ),
        width: "200px",
        right: true
      }*/
    ], []);

  const paginationOptions = {        
    rowsPerPageText: 'Registros por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Todos'        
  };

  const ListarVendas = useCallback(async (pagina = 1, qtd_reg_pagina = 10, termoBusca = "", criterioBusca = "descricao") => {
    setLoading(true);
    try {
      const resp = await api.get("/vendas", { params: { pagina, qtd_reg_pagina, busca: termoBusca, criterio: criterioBusca } });
      setTotalRegistros(resp.data.total_registros);
      setDados(resp.data.registros);
    } catch (err) {
      setTotalRegistros(0);
      setDados([]);
      alert("Erro ao carregar Vendas");
    } finally {
      setLoading(false);
    }
  }, []);

  const Excluir = useCallback((id) => {
    confirmAlert({
      title: 'Exclusão',
      message: 'Confirma exclusão da Venda?',
      buttons: [
        {
          label: 'Sim',
          onClick: async () => {
            try {
              await api.delete("/vendas/" + id);
              ListarVendas(currentPage, qtd_reg_pagina);
            } catch (err) {
              alert("Erro ao excluir o Venda");
            }
          }
        },
        { label: 'Não' }
      ]
    });
  }, [currentPage, qtd_reg_pagina, ListarVendas]);

  const Editar = (id) => {
    const event = new CustomEvent("openSidebar", { detail: { operacao: "edit", codigo: id } });
    window.dispatchEvent(event);
  };

  const Novo = () => {
    const event = new CustomEvent("openSidebar", { detail: { operacao: "new" } });
    window.dispatchEvent(event);
  };

  const ChangePerRows = (newQtd, page, termoBusca, criterioBusca) => {
    setQtdRegPagina(newQtd);
    setCurrentPage(page);
    ListarVendas(page, newQtd, termoBusca, criterioBusca);
  };

  const ChangePage = (page) => {
    setCurrentPage(page);
    ListarVendas(page, qtd_reg_pagina, termoBusca, criterioBusca);
  };

  const RefreshDados = () => ListarVendas(1, qtd_reg_pagina, termoBusca, criterioBusca);
    function handleBuscaChange(e) {
        setTermoBusca(e.target.value); // Atualiza o termo de busca
    }

    function handleCriterioChange(e) {
        setCriterioBusca(e.target.value); // Atualiza o critério de busca
    }

    function handleBuscar() {
        ListarVendas(1, qtd_reg_pagina, termoBusca, criterioBusca); // Executa a busca com termo e critério
    }

  function Montargrafico() {
    // Executa todas as chamadas em paralelo e espera até que todas sejam resolvidas.
    Promise.all([
      api.get("/vendas/montargraficos")
    ])
      .then(([
        graficosResp
      ]) => {
        // Atualiza os estados com os resultados de cada consulta
        setDadosIndicadores(graficosResp.data);
      })
      .catch((err) => {
        console.log("Erro ao carregar dados:", err);
        // Opcional: você pode definir estados de erro para cada gráfico ou indicador
      });
  }
  
  // Função para detectar ambiente (desktop ou mobile)
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addListener(handleResize);
    handleResize();
    Montargrafico();

    // Carrega a lista de produtos na montagem do componente
    ListarVendas(1, qtd_reg_pagina);

    return () => {
      mediaQuery.removeListener(handleResize);
    };
  }, [ListarVendas, qtd_reg_pagina]);

  return (
    <>
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0">
            <Menu page="vendas" />
          </div>
          <div className="col py-3 me-3">
            <div className="mb-5">
              <Busca
                criterioBusca={criterioBusca}
                handleCriterioChange={handleCriterioChange}
                termoBusca={termoBusca}
                handleBuscaChange={handleBuscaChange}
                onBuscar={handleBuscar}
                texto="Digite o termo de busca"
            />
            </div>
            <div className="row">
              <div className="col-md-auto mt-4">
                <Indicador
                  titulo="Vendas Fechadas"
                  valor={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dadosIndicadores.VALOR_FECHADA)}
                  rodape={`${dadosIndicadores.QTD_FECHADA} Vendas`}
                />
              </div>
              <div className="col-md-auto mt-4">
                <Indicador
                  titulo="Vendas Gravadas"
                  valor={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dadosIndicadores.VALOR_GRAVADO)}
                  rodape={`${dadosIndicadores.QTD_GRAVADO} Vendas`}
                />
              </div>
              <div className="col-md-auto mt-4">
                <Indicador
                  titulo="Vendas Canceladas"
                  valor={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dadosIndicadores.VALOR_CANCELADA)}
                  rodape={`${dadosIndicadores.QTD_CANCELADA} Vendas`}
                />
              </div>
              <div className="col-md-auto mt-4">
                <Indicador
                  titulo="Vendas Abertas"
                  valor={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dadosIndicadores.VALOR_ABERTA)}
                  rodape={`${dadosIndicadores.QTD_ABERTA} Vendas`}
                />
              </div>
              <div className="col-md-auto mt-4">
                <Indicador
                  titulo="Vendas Em Andamento"
                  valor={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dadosIndicadores.VALOR_VENDA)}
                  rodape={`${dadosIndicadores.QTD_VENDA} Vendas`}
                />
              </div>              
            </div>
            <div className="bg-white p-4 rounded-4 border">
              <div className="d-flex justify-content-between mb-3">
                <h2>Vendas</h2>
              </div>
              {isMobile ? (
                <div className="card-container">
                  {dados.map((item) => (
                    <div className="card" key={item.CODIGO}>
                      <div className="card-header">{item.NOME}</div>
                        <div className="card-body">
                          <p><strong>Código:</strong> {item.CODIGO}</p>
                          <p><strong>Data Emissão:</strong> {item.DATA_EMISSAO}</p>
                          <p><strong>Cliente:</strong> {item.NOME}</p>
                          <p><strong>Vendedor:</strong> {item.VIRTUAL_VENDEDOR}</p>
                          {/* Verificando o status da conta */}
                          <p><strong>Situação:</strong> 
                            {item.SITUACAO === 'Fechada' && <span style={{ color: 'green' }}> Fechada</span>}
                            {item.SITUACAO === 'Cancelada' && <span style={{ color: 'red' }}> Cancelada</span>}
                            {item.SITUACAO === 'Aberta' && <span style={{ color: 'blue' }}> Aberta</span>}
                            {item.SITUACAO === 'Gravado' && <span style={{ color: 'Yellow' }}> Gravado</span>}
                            {item.SITUACAO === 'Excluido' && <span style={{ color: 'black' }}> Excluído</span>}                            
                          </p>                       
                          <p><strong>Vl. Subtotal:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.SUBTOTAL)}</p>
                          <p><strong>Vl. Troco:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.TROCO)}</p>
                          <p><strong>Vl. Total:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.TOTAL)}</p>
                          {/* Condicional para exibir botões de edição/exclusão apenas para contas abertas */}
                          {/*item.SITUACAO == 'PAGO' && (
                            <>
                              <button onClick={() => Editar(item.CODIGO)} className="btn btn-primary ms-3">
                                <i className="bi bi-pencil-square"></i>
                              </button>
                              <button onClick={() => Excluir(item.CODIGO)} className="btn btn-danger ms-3">
                                <i className="bi bi-trash3-fill"></i>
                              </button>
                            </>
                          )*/}
                        </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="data-table-container">
                  <DataTable
                    columns={columns}
                    data={dados}
                    pagination={true}
                    paginationComponentOptions={paginationOptions}
                    noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                    paginationServer={true}
                    paginationTotalRows={total_registros}
                    onChangeRowsPerPage={ChangePerRows}
                    onChangePage={ChangePage}
                    progressPending={loading}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Vendas;
