// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content{
    height: 100vh;
}

.form-signin {
    max-width: 330px;
    padding: 1rem;
  }

  /*
  .form-signin .form-floating:focus-within {
    z-index: 2;
  }
  */
  
  .form-signin input {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/registro/registro.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,aAAa;EACf;;EAEA;;;;GAIC;;EAED;IACE,6BAA6B;IAC7B,4BAA4B;IAC5B,mBAAmB;EACrB","sourcesContent":[".content{\r\n    height: 100vh;\r\n}\r\n\r\n.form-signin {\r\n    max-width: 330px;\r\n    padding: 1rem;\r\n  }\r\n\r\n  /*\r\n  .form-signin .form-floating:focus-within {\r\n    z-index: 2;\r\n  }\r\n  */\r\n  \r\n  .form-signin input {\r\n    border-bottom-right-radius: 0;\r\n    border-bottom-left-radius: 0;\r\n    margin-bottom: 10px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
