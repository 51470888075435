// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.busca{*/
/*    max-width: 450px;*/
/*}*/
.input-group {
    display: flex;
    align-items: center; /* Alinha verticalmente os itens no centro */
    gap: 8px; /* Espaçamento entre os itens */
}

.form-select {
    flex: 1 0 auto; /* Permite que o select se ajuste conforme o conteúdo e a tela */
    margin-right: 8px; /* Adiciona um espaçamento à direita do select */
}

.form-control.busca {
    flex: 3 1 auto; /* Permite que o campo de busca ocupe mais espaço */
}

.btn-primary {
    flex-shrink: 0; /* Impede que o botão encolha */
}
`, "",{"version":3,"sources":["webpack://./src/components/busca/busca.css"],"names":[],"mappings":"AAAA,UAAU;AACV,wBAAwB;AACxB,IAAI;AACJ;IACI,aAAa;IACb,mBAAmB,EAAE,4CAA4C;IACjE,QAAQ,EAAE,+BAA+B;AAC7C;;AAEA;IACI,cAAc,EAAE,gEAAgE;IAChF,iBAAiB,EAAE,gDAAgD;AACvE;;AAEA;IACI,cAAc,EAAE,mDAAmD;AACvE;;AAEA;IACI,cAAc,EAAE,+BAA+B;AACnD","sourcesContent":["/*.busca{*/\n/*    max-width: 450px;*/\n/*}*/\n.input-group {\n    display: flex;\n    align-items: center; /* Alinha verticalmente os itens no centro */\n    gap: 8px; /* Espaçamento entre os itens */\n}\n\n.form-select {\n    flex: 1 0 auto; /* Permite que o select se ajuste conforme o conteúdo e a tela */\n    margin-right: 8px; /* Adiciona um espaçamento à direita do select */\n}\n\n.form-control.busca {\n    flex: 3 1 auto; /* Permite que o campo de busca ocupe mais espaço */\n}\n\n.btn-primary {\n    flex-shrink: 0; /* Impede que o botão encolha */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
