import "./busca.css";

function Busca({ criterioBusca, handleCriterioChange, termoBusca, handleBuscaChange, onBuscar, texto }) {
    return (
        <div className="input-group">
            <select value={criterioBusca} onChange={handleCriterioChange} className="form-select">
                <option value="descricao">Descrição</option>
                <option value="codigo">Código</option>
                <option value="codigo_barras">Código de Barras</option>
                <option value="grupos">Grupos</option>
                <option value="estoque">Estoque</option>
            </select>
            <input 
                type="text" 
                className="form-control busca" 
                placeholder={texto} 
                value={termoBusca}
                onChange={handleBuscaChange}
            />
            <button className="btn btn-primary" type="button" onClick={onBuscar}>Buscar</button>
        </div>
    );
}

export default Busca;
