import axios from "axios";

const URL = "https://apigestor.cognitivasoftware.com.br";
//const URL = "http://23.22.2.201:3002";

const api = axios.create({
    baseURL: URL,
    auth: {
        username: "Cognitiva",
        password: "51402701"    
    }
});

export default api;